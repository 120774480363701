
import { ref, onMounted, defineComponent } from 'vue';
import { generalStore } from '@/store';
import { SaveProviderAddressRequest } from '@/models/RequestInterfaces';
import router from '@/router';
import { api } from '@/services/Api';
import swal from 'sweetalert2';
import { useI18n } from 'vue-i18n';
import {} from 'google-maps';
import { Loader } from '@googlemaps/js-api-loader';
import { ProviderAddress } from '@/models/Provider';

export default defineComponent({
    name: 'LocationMapProvider',
    async setup() {
        const { t } = useI18n();
        const providerAddress = ref(new ProviderAddress());
        const address = ref();
        const map = ref();
        const googleMap = ref();
        const googleAutocomplete = ref();

        const marker = ref();

        const loader = new Loader({
            apiKey: generalStore.getters.googleMapsApiKey,
            version: 'weekly',
            language: generalStore.getters.currentLanguage,
            libraries: ['places']
        });

        const mapOptions = {
            center: { lat: generalStore.getters.countryLatitude, lng: generalStore.getters.countryLongitude }, // By default Israel coordinates
            zoom: 10
        };

        const autoCompleteOptions = {
            componentRestrictions: { country: generalStore.getters.countryCode },
            types: ['address']
        };

        const setProviderLocation = async () => {
            const geocoder = new window.google.maps.Geocoder();
            return geocoder.geocode({ location: marker.value.position }, async function(results: any, status: any) {
                providerAddress.value.longitude = marker.value.position.lng();
                providerAddress.value.latitude = marker.value.position.lat();
            });
        };

        const latLngChanged = async () => {
            const position = { lat: Number(providerAddress.value.latitude), lng: Number(providerAddress.value.longitude) };
            if (!marker.value) {
                marker.value = new window.google.maps.Marker({
                    position: position,
                    map: googleMap.value
                });
            } else {
                marker.value.setPosition(position);
            }
            googleMap.value.setCenter(position);
            setProviderLocation();
        };

        onMounted(async () => {
            await loader.load();
            const apiResult = await api.getProviderAdress();
            if (apiResult.errorMessage) {
                swal.fire({ icon: 'error', title: t('error-pop-up.oops'), text: apiResult.errorMessage });
                return;
            }
            if (apiResult.data) {
                providerAddress.value = apiResult.data.address;
            }
            googleMap.value = new window.google.maps.Map(map.value, mapOptions);
            window.google.maps.event.addListener(googleMap.value, 'click', function(event: any) {
                if (!marker.value) {
                    marker.value = new window.google.maps.Marker({
                        position: event.latLng,
                        map: googleMap.value
                    });
                } else {
                    marker.value.setPosition(event.latLng);
                }
                setProviderLocation();
            });

            googleAutocomplete.value = new window.google.maps.places.Autocomplete(address.value, autoCompleteOptions);
            window.google.maps.event.addListener(googleAutocomplete.value, 'place_changed', () => {
                const place = googleAutocomplete.value.getPlace();
                if (providerAddress.value && place && place.geometry) {
                    providerAddress.value.latitude = place.geometry?.location.lat();
                    providerAddress.value.longitude = place.geometry?.location.lng();
                    if (!marker.value) {
                        marker.value = new window.google.maps.Marker({
                            position: place.geometry?.location,
                            map: googleMap.value
                        });
                    } else {
                        marker.value.setPosition(place.geometry?.location);
                    }
                    googleMap.value.setCenter(place.geometry?.location);
                }
            });

            if (providerAddress.value.latitude && providerAddress.value.longitude) {
                const position = { lat: providerAddress.value.latitude, lng: providerAddress.value.longitude };
                marker.value = new window.google.maps.Marker({
                    position: position,
                    map: googleMap.value
                });
                googleMap.value.setCenter(position);
                googleMap.value.setZoom(15);
            } else {
                navigator.geolocation.getCurrentPosition(
                    position => {
                        googleMap.value.setCenter({ lat: position.coords.latitude, lng: position.coords.longitude });
                        googleMap.value.setZoom(15);
                    },
                    error => {
                        console.log(error.message);
                    }
                );
            }
        });

        const close = () => router.go(-1);

        const save = async () => {
            if (!marker.value) {
                const errorMessage = !marker.value ? t('alert.pleaseSetMarker') : t('alert.pleaseFillAddress');
                swal.fire({
                    icon: 'info',
                    title: t('error-pop-up.oops'),
                    text: errorMessage
                });
                return;
            }

            swal.showLoading();

            const request: SaveProviderAddressRequest = {
                address: providerAddress.value.address,
                longitude: providerAddress.value.longitude,
                latitude: providerAddress.value.latitude
            };

            const response = await api.saveProviderAddressRequest(request);
            if (response.errorMessage) {
                swal.fire({ icon: 'error', title: t('error-pop-up.oops'), text: response.errorMessage });
                return;
            }

            swal.close();
            close();
        };

        return {
            address,
            providerAddress,
            map,
            marker,
            save,
            close,
            latLngChanged
        };
    }
});
